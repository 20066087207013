import {
  Container,
  Typography,
  Toolbar,
  Box,
  Stack,
  Grid,
  Button,
  Link,
  Card,
  CardActions,
  CardContent,
  CardMedia,
} from "@mui/material";
import { graphql, navigate } from "gatsby";

import * as React from "react";
import { Helmet } from "react-helmet";
// import { getVisitRecord, listPhones } from "../services/redis";
import { json } from "fetchkit";
import ReadRecord from "../components/ReadRecord";
import Layout from "../layout/Layout";

interface Props {
  data: {
    id: string;
    site: {
      siteMetadata: {
        title: string;
      };
    };
    allMdx: {
      nodes: {
        id: string;
        frontmatter: {
          order: number;
          title: string;
        };
      }[];
    };
  };
}
// type ServerData = { phones: string[] };

export default function IndexPage({ data }: Props) {
  // navigate("/directory/2021");
  const [record, setRecord] = React.useState([]);

  React.useEffect(() => {
    const getRecord = async () => {
      try {
        const getResponse = await fetch(`/api/get-visit-records`, json({}));
        const getData = await getResponse.json();
        if (getData.message) {
          throw Error(getData.message);
        }
        setRecord(getData);
      } catch (e) {
        alert((e as Error).message);
      }
    };
    getRecord();
  }, []);
  return (
    <Layout title="">
      <Box
        sx={{
          bgcolor: "background.paper",
          pt: 8,
          pb: 6,
        }}
      >
        <Container maxWidth="md">
          <Typography
            fontSize={{ xs: "28px", md: "56px" }}
            align="center"
            fontWeight={500}
            color="text.primary"
            gutterBottom
          >
            中国NFT产业白皮书藏馆
          </Typography>
          <Typography
            fontSize={{ xs: "14px", md: "20px" }}
            align="center"
            color="text.secondary"
            paragraph
          >
            是一份关于中国数字藏品产业现状及未来发展趋势的权威性报告。该白皮书由行业权威机构和专业学者共同撰写，以全面深入的调研和分析为基础，从行业规模、市场需求、技术应用、政策环境等多个方面全面展示了中国数字藏品产业的现状和发展趋势。该白皮书旨在为数字藏品行业的从业者、投资者、政策制定者等各方提供参考和指导，促进数字藏品行业的健康有序发展。
          </Typography>
          <Stack
            sx={{ pt: { xs: 1, md: 2 } }}
            direction="row"
            spacing={2}
            justifyContent="center"
          ></Stack>
        </Container>
      </Box>
      <Container sx={{ py: { xs: 0, md: 8 } }} maxWidth="md">
        {/* End hero unit */}
        <Grid container spacing={4}>
          {[
            {
              title: "2021中国NFT产业白皮书",
              desc: `北京邮电大学和北京大学联合编写的《2021中国NFT产业白皮书》对数字藏品产业进行了详细剖析，基于充分的调研和数据，对市场规模、竞争格局、用户画像等进行了预估和划分。该报告共发行267份，点击外部链接可浏览详细内容。`,
              year: 2021,
            },
            {
              title: "2022中国NFT产业白皮书",
              desc: `北京邮电大学和北京大学联合编写的《2021中国NFT产业白皮书》对数字藏品产业进行了详细剖析，基于充分的调研和数据，对市场规模、竞争格局、用户画像等进行了预估和划分。该报告共发行267份，点击外部链接可浏览详细内容。`,
              year: 2022,
            },
          ].map((card, key) => (
            <Grid item key={key} xs={12} sm={6}>
              <Card
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <CardMedia
                  component="div"
                  sx={{
                    // 16:9
                    // pt: "56.25%",
                    // 1:1
                    pt: "100%",
                  }}
                  image="https://cdn.joy-art.cn/feishu/whitebook/img/2021%E4%B8%AD%E5%9B%BDNFT%E4%BA%A7%E4%B8%9A%E7%99%BD%E7%9A%AE%E4%B9%A6.gif"
                />
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography gutterBottom variant="h5" component="h2">
                    {card.title}
                  </Typography>
                  <Typography>{card.desc}</Typography>
                </CardContent>
                <CardActions>
                  <Button size="medium" onClick={() => {}}>
                    <Link underline="none" href={`/directory/${card.year}`}>
                      点击阅读
                    </Link>
                  </Button>
                  {/* <Button size="small">Edit</Button> */}
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      {/* <ReadRecord records={record} /> */}
    </Layout>
  );
}
export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    allMdx(sort: { fields: frontmatter___order, order: ASC }) {
      nodes {
        id
        frontmatter {
          order
          title
        }
      }
    }
  }
`;
